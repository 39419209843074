import React from "react"
import Layout from "../../components/layout"
import EventPage from "../../components/members/events"

const EventsPage = () => {
  return (
    <Layout>  
     <EventPage/>
    </Layout>
  )
}

export default EventsPage
